<template>
  <div id="pattern-match-com">
    <h2 class="mt-3 grey--text">{{ testConfig.title }}</h2>
    <div>
      <v-row class="mt-2">
        <v-col md="10">
          <v-row v-show="isTestData">
            <v-col cols="6">
              <h3 class="ml-5 mt-2 grey--text">
                第 {{ curQNum + 1 }} 页，共 {{ qCount }} 页
              </h3>
            </v-col>
            <v-col offset="1" ms="4">
              <time-counter-component
                :counterStart="counterStart"
                :intervalMs="timerIntervalMs"
              >
              </time-counter-component>
            </v-col>
          </v-row>
          <!-- 题目说明，未开始测试时显示 -->
          <img
            v-if="!isTestData"
            v-bind:src="qImg"
            :style="imgStyle"
          />
          <!-- 开始测试后显示 -->
          <div v-if="isTestData">
            <v-container v-for="rowIdx in qSubCount" :key="'row' + rowIdx">
              <v-divider></v-divider>
              <v-row class="ml-1 mt-2">
                <v-col cols="12">
                  <!-- 显示题目样式 -->
                  <span class="idxStyle"
                    >第 {{ curQNum * qSubCount + rowIdx }} 题:
                  </span>

                  <img
                    v-for="item in sampleIdx[rowIdx - 1].length"
                    :key="'imgT' + item"
                    v-bind:src="getImages(sampleIdx[rowIdx - 1][item - 1])"
                    :style="imgSampleStyle"
                  />
                  <!-- 题目内容 -->
                </v-col>
              </v-row>
              <v-row>
                <!-- <v-divider vertical class="ml-3 mr-5 mt-5"></v-divider> -->
                <v-col cols="12">
                  <img
                    v-for="i in imagesIdx[rowIdx - 1].length"
                    :key="'img' + i"
                    v-bind:src="getImages(imagesIdx[rowIdx - 1][i - 1])"
                    :style="imgQuestionStyle"
                  />
                  <br />
                  <!-- 选择框 -->
                  <img src="" alt="" :style="blankImgStyle" />
                  <input
                    type="checkbox"
                    :style="checkboxStyle"
                    v-for="item in imagesIdx[rowIdx - 1].length - 1"
                    :key="'checkbox' + item"
                    v-model="userResult[rowIdx - 1]"
                    :value="item"
                  />
                  <br />
                  <!-- 答案显示框 -->
                  <div v-if="showAnswer">
                    <img src="" alt="" :style="blankImgStyle" />
                    <input
                      type="checkbox"
                      :style="checkboxStyle"
                      v-for="item in imagesIdx[rowIdx - 1].length - 1"
                      :key="'answer' + item"
                      v-model="answers[rowIdx - 1]"
                      :value="item"
                      disabled
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
        <v-col md="2">
          <v-btn
            class="mt-5 ml-5"
            width="90%"
            height="60px"
            color="primary"
            v-if="!isTestStart"
            @click="startTest()"
          >
            开始测试
          </v-btn>
          <br />
          <v-btn
            class="mt-5 ml-5"
            width="90%"
            height="60px"
            color="primary"
            v-if="!disableNavBtn"
            @click="preQuestion()"
            :disabled="disablePreBtn()"
          >
            上一页
          </v-btn>
          <br />
          <v-btn
            class="mt-5 ml-5"
            width="90%"
            height="60px"
            color="primary"
            v-if="!disableNavBtn"
            @click="nextQuestion()"
            :disabled="disableNextBtn()"
          >
            下一页
          </v-btn>
          <br />
          <v-btn
            class="mt-5 ml-5"
            width="90%"
            height="60px"
            color="primary"
            v-if="isTestStart"
            @click="doneTest()"
          >
            提交答案
          </v-btn>
          <v-btn
            class="mt-5 ml-5"
            width="90%"
            height="60px"
            color="primary"
            v-if="checkErrors"
            @click="setAnswerVisible()"
          >
            <span v-if="!answerVisible">显示答案</span>
            <span v-if="answerVisible">隐藏答案</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import { SEPARATE_FLAG, SEPARATE_SUB_FLAG } from '@/const'
import { intCompare } from '@/utils'
import timeCounterComponent from '@/components/common/timeCounterComponent'

export default {
  components: { timeCounterComponent },

  name: 'pattern-match-component',
  props: {
    qImgCount: null,
  },
  mounted() {
    this.setImageHeight(document.body.clientHeight)
    this.setImgStyle()
  },
  watch: {
    getWindowWidth: {
      handler() {
        this.setImgStyle()
      },
      deep: true,
    },
    getWindowHeight: {
      handler() {
        this.setImageHeight(this.getWindowHeight)
      },
      deep: true,
    },
  },
  data() {
    return {
      // 样本图片索引
      sampleIdx: [[], [], []],
      // 问题图片索引
      imagesIdx: [[], [], []],
      // 用户选择答案
      userResult: [],
      // 当前页面问题答案
      answers: [[], [], []],
      // 样本图片样式
      imgSampleStyle: {
        // position: 'float',
        'margin-left': '15px',
        'margin-bottom': '2px',
        'margin-top': '10px',
        width: '30px',
        height: '30px',
      },
      // 题目图片样式
      imgQuestionStyle: {
        // position: 'float',
        'margin-left': '15px',
        'margin-bottom': '2px',
        'margin-top': '5px',
        width: '30px',
        height: '30px',
      },
      // 答案选择框样式
      checkboxStyle: {
        // position: 'float',
        'margin-left': '15px',
        width: '30px',
        height: '15px',
      },
      imgML: '',
      blankImgStyle: {
        'margin-left': this.imgML,
      },
    }
  },
  methods: {
    // 设置说明图片高度
    setImageHeight(value) {
      // 72px footer+margin, 64 px header bar, 36px title
      const winHeight = ((value - 72 - 64 - 36) * 4) / 5
      this.imgStyle.height = winHeight + 'px'
    },
    // 设置问题图片样式
    setImgStyle() {
      let qAreaWidth = Math.trunc((this.getMainAreaWidth() * 10) / 12 - 24)
      if (qAreaWidth > 1785) {
        qAreaWidth = 1785
      }
      let imgWidth = Math.trunc(
        (qAreaWidth - (this.qImgCount + 1) * 15) / (this.qImgCount + 1)
      )
      if (imgWidth > 65) {
        imgWidth = 65
      }
      this.imgSampleStyle.width = imgWidth + 'px'
      this.imgSampleStyle.height = imgWidth + 'px'
      this.imgQuestionStyle.width = imgWidth + 'px'
      this.imgQuestionStyle.height = imgWidth + 'px'
      this.checkboxStyle.width = imgWidth + 'px'
      let deltaPx = imgWidth - 2
      if (deltaPx > 24) {
        deltaPx = 24
      }
      this.blankImgStyle = { 'margin-left': deltaPx + 'px' }
    },
    // 获取图片
    getImages(idx) {
      if (idx === null) {
        return ''
      }
      const image_name = 'pattern_' + idx + '.jpg'
      return require('../../assets/patternImages/' + image_name)
    },
    // 根据题目信息，设置图像索引
    procQuestionInfo() {
      if (this.questions === null) {
        // 问题列表为空，返回
        return
      }
      // 将字符串转为JSON格式
      // eslint-disable-next-line quotes
      const qInfo = JSON.parse(
        this.questions[this.curQNum].q_info.replaceAll("'", '"')
      )
      // 设置答题信息
      const qResult = this.questions[this.curQNum].q_result.split(SEPARATE_FLAG)
      const result_len = qResult.length
      this.qSubCount = qInfo.length
      for (let idx = 0; idx < this.qSubCount - result_len; idx++) {
        qResult.push('')
      }
      for (let idx = 0; idx < qInfo.length; idx++) {
        this.sampleIdx[idx] = qInfo[idx].sample_idx
        this.imagesIdx[idx] = qInfo[idx].image_idx
        this.answers[idx] = qInfo[idx].answers
        if (qResult[idx] === '') {
          this.userResult[idx] = []
        } else {
          this.userResult[idx] = qResult[idx].split(SEPARATE_SUB_FLAG)
        }
      }
    },
    // 重载方法：问题序号更新
    procQnumUpdate() {
      this.procQuestionInfo()
    },
    // 重载方法：开始新测试
    procNewTest() {
      this.procQuestionInfo()
    },
    // 重载方法：读取当前问题答案
    getQuestionResult() {
      const result = []
      for (const item of this.userResult) {
        result.push(item.sort(intCompare).join(SEPARATE_SUB_FLAG))
      }
      return result.join(SEPARATE_FLAG)
    },
  },
  computed: {
    getWindowWidth() {
      return this.$store.getters.getScreenWidth
    },
    // getLocalImg() {
    //   if (this.signImage === '') {
    //     return this.signImage
    //   }
    //   return require('../assets/' + this.signImage)
    // },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
.codeIndex {
  font-family: 微软雅黑;
  font-size: 26px;
  font-weight: bold;
  border-bottom: 1px dashed #cccccc;
  color: #255e95;
}
.checkbox {
  position: float;
  margin-left: 15px;
  width: 30px;
}
.idxStyle {
  position: float;
  font-size: 150%;
  margin: 0 auto;
}
</style>
