<template>
  <div id="code-calc-p">
    <code-calc-component
      :testConfig="testConfig"
      :questions="questions"
      :testData="testData"
      :initImage="initImage"
      @doneTest="doneTest"
      @startTest="startTest"
      @submitAnswer="submitAnswer"
    ></code-calc-component>
    <wait-result-dialog
      id="resultDialog"
      :testResult="testResult"
      :showDialog="waitResultDialog"
      @closeResultDialog="closeResultDialog"
      @checkErrors="checkErrors"
    ></wait-result-dialog>
  </div>
</template>

<script>
import CodeCalcComponent from '@/components/pc/CodeCalcPageComponent'
import waitResultDialog from '@/components/pc/waitRespDialog.vue'
import BaseCodeCalc from '@/views/common/BaseCodeCalc.vue'

export default {
  components: { CodeCalcComponent, waitResultDialog },

  extends: BaseCodeCalc,
}
</script>

<style lang="scss" scoped>
</style>
