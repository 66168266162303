<template>
  <div id="dashboard">
    <v-row class="mt-5">
      <v-col cols="12" md="4">
        <!-- 当天测试记录 -->
        <ChartPie
          class="mt-15"
          :chartTitle="todayTestSummary.chartTitle"
          :chartName="todayTestSummary.chartName"
          :chartXData="todayTestSummary.chartData"
          :showData="todayTestSummary.showPieData"
        ></ChartPie>
        <!-- 历史数量统计 -->
        <ChartPie
          :chartTitle="testSummary.chartTitle"
          :chartName="testSummary.chartName"
          :chartXData="testSummary.chartData"
          :showData="testSummary.showPieData"
        ></ChartPie>
      </v-col>
      <v-col cols="12" md="8">
        <!--  -->
        <v-container>
          <span class="ml-10">测试类型: </span>
          <v-btn-toggle v-model="testType" tile color="primary accent-3" group>
            <v-btn
              v-for="(v, k) in testTypeOptions"
              :key="'testTypsOption' + k"
              :value="v"
              >{{ v }}</v-btn
            >
          </v-btn-toggle>
        </v-container>
        <v-row>
          <v-col cols="12" md="6">
            <!-- 完成率 -->
            <ChartLine
              :chartTitle="completeRate.chartLineTitle"
              :chartName="completeRate.chartLineName"
              :chartXData="completeRate.xData"
              :chartYData="completeRate.yData"
              :averageLine="completeRate.averageLine"
              :maxPoint="completeRate.maxPoint"
              :minPoint="completeRate.minPoint"
              :percent="true"
            ></ChartLine>
            <!-- 时间统计 -->
            <ChartLine
              :chartTitle="totalTime.chartLineTitle"
              :chartName="totalTime.chartLineName"
              :chartXData="totalTime.xData"
              :chartYData="totalTime.yData"
              :averageLine="totalTime.averageLine"
              :maxPoint="totalTime.maxPoint"
              :minPoint="totalTime.minPoint"
              :yUnit="'秒'"
            ></ChartLine>
          </v-col>
          <v-col md="6">
            <!-- 正确率 -->
            <ChartLine
              :chartTitle="correctRate.chartLineTitle"
              :chartName="correctRate.chartLineName"
              :chartXData="correctRate.xData"
              :chartYData="correctRate.yData"
              :averageLine="correctRate.averageLine"
              :maxPoint="correctRate.maxPoint"
              :minPoint="correctRate.minPoint"
              :percent="true"
            ></ChartLine>

            <!-- 单位时间统计 -->
            <ChartLine
              :chartTitle="averageTime.chartLineTitle"
              :chartName="averageTime.chartLineName"
              :chartXData="averageTime.xData"
              :chartYData="averageTime.yData"
              :averageLine="averageTime.averageLine"
              :maxPoint="averageTime.maxPoint"
              :minPoint="averageTime.minPoint"
              :yUnit="'秒'"
            ></ChartLine>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import BaseDashboard from '@/views/common/BaseDashboard.vue'

export default {
  extends: BaseDashboard
}
</script>

<style lang="scss" scoped>
</style>
