export type UserModel = {
    id?: number;
    username: string;
    real_name: string;
    real_name_pinyin: string;
    openid: string;
    mobile: string;
    is_active: boolean;
    id_card: string;
    email: string;
    description: string;
    date_joined: string;
}

export const defaultUser = {
    username: '',
    password: '',
    real_name: '',
    openid: '',
    mobile: '',
    id_card: '',
    email: ''
}
