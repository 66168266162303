<template>
  <div id="schute-grid-p">
    <h2 class="mt-3 grey--text">舒尔特方格练习</h2>
    <v-row>
      <v-col cols="12" md="8">
        <v-row class="mt-2">
          <v-col cols="9">
            <span style="margin-left: 10%">方格数量：</span>
            <v-btn-toggle
              v-model="gameNum"
              tile
              group
              background-color="primary"
            >
              <v-btn
                v-for="(v, k) in gameConfig"
                :key="'gameNum' + k"
                :value="k"
                >{{ v }}</v-btn
              >
            </v-btn-toggle>
          </v-col>

          <v-col cols="3">
            <span>难度：</span>
            <v-btn-toggle
              v-model="difficulty"
              tile
              group
              style="margin-left: 0%"
              background-color="green"
            >
              <v-btn value="easy"> 简单 </v-btn>
              <v-btn value="hard"> 困难 </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row mt-5>
          <table
            id="schultegrid-table"
            border="1"
            cellspacing="1"
            align="center"
            class="table"
          >
            <tr v-for="xIdx in gridSize" :key="'xIdx' + xIdx">
              <td
                v-for="yIdx in gridSize"
                :key="'yIdx' + yIdx"
                align="center"
                :id="getCellId(xIdx, yIdx)"
                :style="gridStyle"
                @click="select(xIdx, yIdx)"
              >
                {{ showData(xIdx, yIdx) }}
              </td>
            </tr>
          </table>
        </v-row>
      </v-col>
      <v-col cols="4">
        <!-- 操作栏 -->
        <v-btn
          class="mt-15 ml-8"
          width="80%"
          height="60px"
          color="primary"
          v-if="!gameStart"
          @click="startGame()"
        >
          开始游戏
        </v-btn>
        <v-btn
          class="mt-15 ml-8"
          width="80%"
          height="60px"
          color="primary"
          v-if="gameStart"
          @click="reStartGame()"
        >
          重新开始
        </v-btn>
        <v-btn
          class="mt-5 ml-8"
          width="80%"
          height="60px"
          color="primary"
          v-if="gameStart"
          @click="stopGame()"
        >
          停止游戏
        </v-btn>

        <v-container style="width: 90%; margin: 0 auto" v-if="gameStart">
          <v-row class="mt-3">
            <table
              border="0"
              cellspacing="10"
              align="center"
              width="80%"
              style="margin: 0 auto"
            >
              <tr>
                <!-- <td align="center" class="expect-num">
                  <p class="cell-word">下一个</p>
                  <p>{{ expectNum }}</p>
                </td> -->
                <td align="center" class="highest-record">
                  <p class="cell-word">计时器</p>
                  <p>{{ counterToS }}秒</p>
                </td>
                <td align="center" class="highest-record">
                  <p class="cell-word">最高纪录</p>
                  <p>{{ getHighestRecord() }}秒</p>
                </td>
              </tr>
            </table>
          </v-row>
          <v-row>
            <table
              border="0"
              cellspacing="10"
              align="center"
              width="80%"
              style="margin: 0 auto"
            >
              <tr>
                <td align="center" class="expect-num">
                  <p class="expect-num-word">下一个</p>
                  <p>{{ expectNum }}</p>
                </td>
              </tr>
            </table>
          </v-row>
        </v-container>
        <v-container
          style="width: 90%; margin: 0 auto; margin-top: 15%"
          v-if="!gameStart"
        >
          <ChartLine
            :chartTitle="chartTitle"
            :chartName="chartName"
            :chartXData="xData"
            :chartYData="yData"
            :averageLine="averageLine"
            :maxPoint="true"
            :minPoint="true"
            :yUnit="'秒'"
          >
          </ChartLine>
        </v-container>
      </v-col>
    </v-row>
    <!-- 结束游戏对话框 -->
    <v-dialog v-model="showResult" max-width="400px">
      <v-card>
        <v-card-title class="headline"> 游戏完成 </v-card-title>
        <v-card-text>
          <v-container>
            <p v-if="newRecord" class="new-record">恭喜获得新纪录!</p>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="方格数量"
                  class="text-align--center"
                  v-model="gameConfig[gameInfo.gridNum]"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="最高记录"
                  v-model="highRecords"
                  align="center"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="当前记录"
                  v-model="gameInfo.spendTimeS"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="平均手速"
                  suffix="秒"
                  v-model="gameInfo.averageRate"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogConfirm(false)"
            >确认</v-btn
          >
          <!-- <v-btn color="green darken-1" text @click="dialogConfirm(true)"
            >重新开始</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChartLine from '@/components/charts/eChartLine'
import BaseSchulteGridComponent from '@/components/common/BaseSchulteGridComponent.vue'

export default {
  components: { ChartLine },
  data() {
    return {
      //   网格样式
      gridStyle: {
        'font-size': '250%',
        'font-weight': 'bold',
        color: '#255e95',
        height: '0',
        padding: '0% 0 0% 0',
        'background-color': 'khaki',
        'user-select': 'none',
      },

      //   游戏网格数配置
      gameConfig: {
        3: '3X3',
        4: '4X4',
        5: '5X5',
        6: '6X6',
        7: '7X7',
        8: '8X8',
        9: '9X9',
      },
    }
  },
  extends: BaseSchulteGridComponent,
}
</script>

<style lang="scss" scoped>
.table {
  align-content: center;
  margin: auto;
  height: 600px;
  width: 600px;
}
.expect-num {
  font-size: 800%;
  font-weight: bold;
  background: burlywood;
  color: red;
  padding: 5% 0 5% 0;
}
.expect-num-word {
  font-size: large;
  color: white;
  align-content: center;
}
.cell-word {
  font-size: small;
  color: white;
  align-content: center;
}
.highest-record {
  font-size: 150%;
  font-weight: bold;
  background: rgb(106, 182, 135);
  color: blue;
  // background-color: khaki;
  padding: 1% 0 0% 0;
}
.new-record {
  color: red;
  font-size: x-large;
}
.v-application p {
  margin-bottom: 1px;
}
</style>
