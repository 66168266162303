









import { Component, Vue } from 'vue-property-decorator'
import Navbar from '@/components/pc/Navbar.vue'

@Component({
  components: {
    Navbar
  },
})
export default class Main extends Vue {}
