<template>
  <div id="mental-calc">
    <h2 class="mt-3 grey--text">心算练习</h2>
    <v-row class="mt-5 ml-1">
      <v-col cols="8">
          <span class="ml-1">游戏难度：</span>
          <v-btn-toggle
            v-model="selectedDifficulty"
            tile
            color="primary accent-3"
            group
          >
            <v-btn
              v-for="(v, k) in gameConfig"
              :key="'difficulty' + k"
              :value="k"
              >{{ v }}</v-btn
            >
          </v-btn-toggle>
        <img
          v-if="!gameStart"
          src="../../assets/initImages/mental_calc.jpg"
          :style="imgStyle"
        />
        <v-container class="mt-5" v-if="gameStart">
          <v-card class="mx-auto" width="100%">
            <v-card-title>
              <!-- <v-icon large left> mdi-twitter </v-icon> -->
              <span class="font-weight-light">题目信息：</span>
            </v-card-title>
            <v-row align="center">
              <v-checkbox
                class="ml-12"
                readonly
                v-for="(item, idx) in gameInfo.gameData"
                :key="'checkbox' + idx"
                id="idx"
                :label="'第' + (idx + 1) + '题'"
                v-model="item.result"
              ></v-checkbox>
            </v-row>
            <v-row>
              <v-col offset="1" cols="4">
                <p class="parm-style">{{ parmA }}</p>
              </v-col>
              <v-col cols="2">
                <v-avatar rounded="" size="80px" class="mt-5">
                  <img src="../../assets/signImages/sign_mul.png" />
                </v-avatar>
              </v-col>
              <v-col cols="4">
                <p class="parm-style">{{ parmB }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col offset="3" cols="4">
                <v-text-field
                  ref="userInput"
                  class="input-result"
                  v-model="userResult"
                  label="请输入答案"
                  height="80px"
                  autocomplete="off"
                  hint="按回车键提交答案"
                  clearable
                  outlined
                  @keyup.enter="checkResult"
                  @click:clear="clearResult"
                ></v-text-field>
                <p class="error-tip">{{ errorTip }}</p>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="4" >
        <!-- 操作栏 -->
        <v-btn
          class="mt-5 ml-8"
          width="80%"
          height="60px"
          color="primary"
          v-if="!gameStart"
          @click="startGame()"
        >
          开始游戏
        </v-btn>
        <v-btn
          class="mt-5 ml-5"
          width="80%"
          height="60px"
          color="primary"
          v-if="gameStart"
          @click="stopGame()"
        >
          停止游戏
        </v-btn>
        <v-container style="width: 100%; margin: 0 auto" v-if="gameStart">
          <v-row class="mt-3">
            <table
              border="0"
              cellspacing="10"
              align="center"
              width="80%"
              style="margin: 0 auto"
            >
              <tr>
                <td align="center" class="highest-record">
                  <p class="cell-word">计时器</p>
                  <p>{{ counterToS }}秒</p>
                </td>
                <td align="center" class="highest-record">
                  <p class="cell-word">最高纪录</p>
                  <p>{{ getHighestRecord() }}秒</p>
                </td>
              </tr>
            </table>
          </v-row>
        </v-container>
        <v-container
          style="width: 100%; margin: 0 auto; margin-top: 5%"
          v-if="!gameStart"
        >
          <ChartLine
            :chartTitle="chartTitle"
            :chartName="chartName"
            :chartXData="xData"
            :chartYData="yData"
            :averageLine="averageLine"
            :maxPoint="true"
            :minPoint="true"
            :yUnit="'秒'"
          >
          </ChartLine>
        </v-container>
      </v-col>
    </v-row>
    <!-- 结束游戏对话框 -->
    <v-dialog v-model="showResult" max-width="400px">
      <v-card>
        <v-card-title class="headline"> 游戏完成 </v-card-title>
        <v-card-text>
          <v-container>
            <p v-if="newRecord" class="new-record">恭喜获得新纪录!</p>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="游戏难度"
                  class="text-align--center"
                  v-model="gameConfig[gameInfo.difficulty]"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="最高记录"
                  v-model="highRecords"
                  align="center"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5">
                <v-text-field
                  label="当前记录"
                  v-model="gameInfo.spendTimeS"
                  suffix="秒"
                  readonly
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  label="平均手速"
                  suffix="秒"
                  v-model="gameInfo.averageRate"
                  readonly
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogConfirm()"
            >确认</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ChartLine from '@/components/charts/eChartLine'
import BaseMentalCalcComponent from '@/components/common/BaseMentalCalcComponent.vue'

export default {
  components: { ChartLine },
  mounted() {
    this.setImageHeight(document.body.clientHeight)
  },
  watch: {
    getWindowHeight: {
      handler() {
        this.setImageHeight(this.getWindowHeight)
      },
      deep: true,
    },
  },
  data() {
    return {
      //   游戏难度配置
      gameConfig: {
        easy: '一位数X两位数',
        normal: '两位数X两位数',
        hard: '三位数X两位数',
        nightmare: '三位数X三位数',
      },
      // 图片样式
      imgStyle: {
        display: 'inline-block',
        width: '90%',
        'max-width': '90%',
        height: '300px',
        margin: '0 auto',
      },
    }
  },
  methods: {
    // 设置图片高度
    setImageHeight(value) {
      // 72px footer+margin, 64 px header bar, 36px title
      const winHeight = ((value - 72 - 64 - 36) * 3) / 4
      this.imgStyle.height = winHeight + 'px'
    },
  },

  extends: BaseMentalCalcComponent,
}
</script>

<style lang="scss" scoped>
.table {
  align-content: center;
  margin: auto;
}
.cell-word {
  font-size: small;
  color: white;
  align-content: center;
}
.highest-record {
  font-size: 150%;
  font-weight: bold;
  background: rgb(106, 182, 135);
  color: blue;
  // background-color: khaki;
  padding: 5% 0 1% 0;
}
.new-record {
  color: red;
  font-size: x-large;
}
.parm-style {
  font-size: 80px;
  padding: auto;
  margin: auto;
  color: darkblue;
  text-align: center;
}
.input-result {
  font-size: 200%;
  padding: 0%;
  // margin-top: 15px;
  margin: auto;
}
.error-tip {
  font-size: 15px;
  color: red;
}
.v-application p {
  margin-bottom: 1px;
}
</style>
