<template>
  <div id="login-pc" class="mt-15">
    <v-row>
      <v-col
        offset-md="1"
        xs="12"
        md="5"
        style="min-height: 1px"
        v-if="isLargeScreen()"
      >
        <v-card height="700px" width="500px" class="img-class">
          <img :src="loginImage" height="100%" width="100%" />
        </v-card>
      </v-col>
      <v-col xs="12" offset-md="1" md="4">
        <v-form ref="form" v-model="valid" class="mt-10 pl-10 pr-10">
          <h1 class="text-center">用户登录</h1>
          <v-text-field
            class="mt-15"
            v-model="username"
            label="请输入注册手机号"
            :rules="[rules.required]"
          >
          </v-text-field>
          <v-text-field
            class="mt-15"
            v-model="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            label="请输入密码"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <!-- <v-checkbox id="remember" v-model="rememberState" label="记住当前用户" @click="clickRember()"></v-checkbox> -->
          <v-row>
            <v-col cols="7">
              <input id="remember" type="checkbox" v-model="rememberState" />
              <span class="remember-user">记住当前用户</span>
            </v-col>
            <v-col cols="5">
              <span class="forget-pwd" @click="forgetPassword()">忘记密码</span>
            </v-col>
          </v-row>
          <v-btn
            block
            class="mt-10"
            color="primary"
            x-large
            v-on:click.prevent="signIn()"
            :disabled="!valid"
          >
            登录
          </v-btn>
        </v-form>
        <v-row class="mt-3">
          <!-- <v-col md="3">
            <v-btn text color="indigo">忘记密码</v-btn>
          </v-col> -->
          <v-spacer></v-spacer>
          <v-col md="3">
            <v-btn text color="indigo" @click="register()">用户注册</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import { httpPost } from '@/api/request'
// import { getUserInfo } from '@/api/user-api/userApi'
// import { getRandomInt } from '@/utils'
// import { LOGIN_IMAGE_COUNT } from '@/const'
import Base from '@/views/Base.vue'
import BaseLogin from '@/views/common/BaseLogin.vue'

export default {
  extends: BaseLogin,
  mixins: [Base],
}
</script>

<style lang="scss" scoped>
.remember-user {
  font-size: 90%;
  margin-left: 5px;
}
.forget-pwd {
  display:block;
  font-size: 90%;
  color: blue;
  text-align: right;
  text-decoration:underline
}
.img-class {
  overflow: hidden;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
