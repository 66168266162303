<template>
  <v-dialog v-model="showDialog" max-width="700" persistent hide-overlay>
    <v-card>
      <v-card-title class="headline"> 测试结果 </v-card-title>
      <v-card-text>
        <v-container v-if="!resultReady">
          <p style="font-size: 20px">测试结果处理中，请稍后 . . .</p>
          <v-progress-linear
            indeterminate
            color="primary"
            class="mt-5 mb-5"
          ></v-progress-linear>
        </v-container>
        <v-container v-if="resultReady">
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field label="开始时间" v-model="startTime" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" offset-md="1">
              <v-text-field label="完成时间" v-model="endTime" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" offset-md="1">
              <v-text-field label="测试时长" v-model="spendTime" readonly>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field label="题目数量" v-model="questionCount" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" offset-md="1">
              <v-text-field label="答题数量" v-model="answeredCount" readonly>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" offset-md="1">
              <v-text-field label="正确数量" v-model="correctCount" readonly>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="resultReady">
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="confirm">确认</v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="check"
          v-if="answeredCount - correctCount > 0"
          >查看错题</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { TestResult } from '@/models'
import { formatDateTime } from '@/utils'

export default {
  props: {
    // 是否显示对话框
    showDialog: {
      type: Boolean,
      default: true,
    },
    // 测试结果
    testResult: {
      type: TestResult,
      default: null,
    },
  },
  methods: {
    // 确认按钮
    confirm() {
      this.$emit('closeResultDialog')
    },
    // 查看错题
    check() {
      this.$emit('checkErrors', this.testResult)
    },
  },
  computed: {
    // 结果是否正常
    resultReady() {
      if (this.testResult) {
        return true
      }
      return false
    },
    // 开始时间
    startTime() {
      if (this.testResult === null) {
        return ''
      } else {
        return formatDateTime(this.testResult.start_time)
      }
    },
    // 完成时间
    endTime() {
      if (this.testResult === null) {
        return ''
      } else {
        return formatDateTime(this.testResult.end_time)
      }
    },
    // 测试时长
    spendTime() {
      if (this.testResult === null) {
        return ''
      } else {
        const minute = parseInt(this.testResult.spend_time / 60)
        const seconds = this.testResult.spend_time % 60
        if (minute > 0) {
          return minute + '分钟' + seconds + '秒'
        } else {
          return seconds + '秒'
        }
      }
    },
    // 题目数量
    questionCount() {
      if (this.testResult === null) {
        return ''
      } else {
        return this.testResult.q_count * this.testResult.q_subcount
      }
    },
    // 答题数量
    answeredCount() {
      if (this.testResult === null) {
        return ''
      } else {
        return this.testResult.answered
      }
    },
    // 正确数量
    correctCount() {
      if (this.testResult === null) {
        return ''
      } else {
        return this.testResult.correct
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
