<template>
  <div id="user">
    <h2 class="mt-3 grey--text">历史测试数据</h2>
    <!-- 用户数据Data Table -->
    <v-data-table
      :headers="headers"
      :items="records"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      class="elevation-1 mt-8"
    >
      <!-- Data Table 上部信息，包括新增按钮，查询框等 -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>用户历史测试数据</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="checkError(item)" v-if="!disableCheckErrors(item)"> mdi-magnify </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
// import { getUserTestData } from '@/api/test-api'
// import { formatSecondsToStr, formatTime } from '@/utils'
// import { TEST_TYPE_ROUTING_MAP } from '@/const'
import BaseUserHistory from '@/views/common/BaseUserHistory.vue'

export default {
  extends: BaseUserHistory
}
</script>

<style lang="scss" scoped>
</style>
