<template>
  <div id="register-pc">
    <v-row>
      <v-col offset-md="1" md="5" v-if="isLargeScreen()">
        <v-card height="700px" width="500px" class="mt-15 ml-15">
          <img :src="registerImage" height="100%" width="100%" />
        </v-card>
      </v-col>
      <v-col xs="12" offset-md="1" md="4">
        <v-form ref="form" v-model="valid" class="mt-10 pa-10">
          <h1 class="text-center">注册用户</h1>
          <v-text-field
            class="mt-15"
            v-model="userInfo.mobile"
            label="注册手机号"
            :rules="[rules.mobile]"
            autocomplete="off"
          ></v-text-field>
          <v-text-field
            v-model="userInfo.nickname"
            label="昵称"
            :rules="[rules.nickname]"
            autocomplete="off"
          >
          </v-text-field>
          <v-text-field
            v-model="userInfo.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.password]"
            :type="showPassword ? 'text' : 'password'"
            label="请输入密码"
            hint="请输入8位密码"
            counter
            autocomplete="off"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-text-field
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.samePwd]"
            :type="showPassword ? 'text' : 'password'"
            label="再次输入密码"
            counter
            autocomplete="off"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-row>
            <v-col cols="8">
              <v-text-field
                v-model="identifyCode"
                label="请输入验证码"
                autocomplete="off"
                clearable
              >
              </v-text-field>
            </v-col>
            <v-col cols="4">
              <div @click="refreshCode">
                <s-identify :identifyCode="randomCode"></s-identify>
              </div>
            </v-col>
          </v-row>
          <v-text-field
            v-model="userInfo.rand_code"
            label="请输入手机验证码"
            autocomplete="off"
            :rules="[rules.randCode]"
            clearable
          >
            <template v-slot:append-outer>
              <v-btn
                depressed
                class="primary"
                :disabled="!enableSendSms()"
                @click="getMobileCode()"
                >{{ smsContent }}</v-btn
              >
            </template>
          </v-text-field>
          <v-row align="center" class="mt-15">
            <v-btn
              width="30%"
              height="60px"
              class="ml-5"
              color="primary"
              v-on:click.prevent="toLogin()"
            >
              返回登录
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              width="30%"
              height="60px"
              class="mr-5"
              color="primary"
              v-on:click.prevent="register()"
              :disabled="!valid"
            >
              注册用户
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseRegister from '@/views/common/BaseRegister.vue'
import SIdentify from '@/components/common/identifyCode.vue'

export default {
  components: { SIdentify },
  mounted() {
    this.refreshCode()
  },
  extends: BaseRegister,
}
</script>

<style lang="scss" scoped>
</style>
