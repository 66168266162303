<template>
  <div id="mentalCalc-p">
    <mental-calc> </mental-calc>
  </div>
</template>

<script>
import mentalCalc from '@/components/pc/MentalCalcComponent.vue'
import BaseMentalCalc from '@/views/common/BaseMentalCalc.vue'

export default {
  components: { mentalCalc },
  extends: BaseMentalCalc,
}
</script>

<style lang="scss" scoped>
</style>
