<script>
import BaseGameComponent from '@/components/common/BaseGameComponent'
import { getRandomInt } from '@/utils'
import {
  GAME_MODE_NORMAL,
  GAME_TYPE_MENTAL_CALC,
  GAME_MENTAL_CALC_SUB_COUNT,
} from '@/const'

export default {
  mounted() {
    this.retrieveHighestRecord()
    this.retrieveUserGameRecords()
  },
  watch: {
    selectedDifficulty: {
      handler() {
        if (this.gameStart === true) {
          // 当前游戏进行中，不操作
          return
        }
        this.gameInfo.difficulty = this.selectedDifficulty
        this.retrieveUserGameRecords()
      },
    },
    curIdx: {
      handler() {
        if (this.curIdx === -1) {
          return
        }
        this.updateParms()
      },
      deep: true,
    },
    gameStart: {
      handler() {
        if (this.gameStart === true) {
          // 输入框通过gameStart字段控制，设置时html模板未被加载，无法获取输入框实例
          // 使用异步延迟机制即可解决，Promise比SetTimeout优先级更高，二者均可
          // setTimeout(() => {
          // }, 0)
          Promise.resolve().then(() => {
            this.$refs.userInput.focus()
          })
        }
      },
    },
  },
  data() {
    return {
      // 输入结果
      userResult: null,
      //   游戏难度配置
      gameConfig: {
        easy: '一位数X两位数',
        normal: '两位数X两位数',
        hard: '三位数X两位数',
        nightmare: '三位数X三位数',
      },
      //   游戏信息
      gameInfo: {
        gameType: GAME_TYPE_MENTAL_CALC,
        gameMode: GAME_MODE_NORMAL,
        gameSubCount: GAME_MENTAL_CALC_SUB_COUNT,
        gameData: [],
        difficulty: 'normal',
        startTime: null,
        endTime: null,
        spendTimeMS: null,
        spendTimeS: null,
        averageRate: null,
      },
      // 当前题目索引
      curIdx: 0,
      // 页面显示参数
      parmA: null,
      parmB: null,
      // 错误提示信息
      errorTip: null,
      // echart参数
      chartName: '游戏时间'
    }
  },
  methods: {
    // // 生成乘法计算题
    generateMultipy(parmRange) {
      const aMin = parmRange[0][0]
      const aMax = parmRange[0][1]
      const bMin = parmRange[1][0]
      const bMax = parmRange[1][1]
      const parmA = getRandomInt(aMax, aMin)
      const parmB = getRandomInt(bMax, bMin)
      const answer = parmA * parmB
      return {
        parmA: parmA,
        parmB: parmB,
        answer: answer,
        result: false,
      }
    },
    // 重载方法：生成计算题
    generateGameData() {
      const levelMap = {
        easy: [
          [20, 99],
          [2, 9],
        ],
        normal: [
          [20, 99],
          [20, 99],
        ],
        hard: [
          [100, 999],
          [10, 99],
        ],
        nightmare: [
          [100, 999],
          [100, 999],
        ],
      }
      const parmRange = levelMap[this.gameInfo.difficulty]
      this.gameInfo.gameData = []
      for (let idx = 0; idx < GAME_MENTAL_CALC_SUB_COUNT; idx++) {
        this.gameInfo.gameData.push(this.generateMultipy(parmRange))
      }
    },
    // 重载方法：配置启动游戏参数
    configStartGameParms() {
      this.userResult = null
      this.curIdx = 0
      this.updateParms()
    },
    // 重载方法：停止游戏，重置参数
    setStopGameParms() {
      this.userResult = null
      this.updateParms()
      // 设置为-1，表示无效
      this.curIdx = -1
    },
    // 检查输入计算结果
    checkResult() {
      if (
        parseInt(this.userResult) !== this.gameInfo.gameData[this.curIdx].answer
      ) {
        this.errorTip = '答案错误, 请重试!'
        return
      }
      // 结果正确
      this.errorTip = null
      this.gameInfo.gameData[this.curIdx].result = true

      if (this.curIdx === this.gameInfo.gameSubCount - 1) {
        // 当前为最后一题，结束
        this.endGame()
      } else {
        this.curIdx += 1
        this.userResult = null
      }
    },
    // 更新页面参数
    updateParms() {
      if (this.gameInfo.gameData.length === 0) {
        this.parmA = null
        this.parmB = null
      } else {
        this.parmA = this.gameInfo.gameData[this.curIdx].parmA
        this.parmB = this.gameInfo.gameData[this.curIdx].parmB
      }
    },
    // 清除输入结果
    clearResult() {
      this.errorTip = null
    },
  },
  computed: {
    highRecords() {
      return this.getHighestRecord()
    },
  },
  extends: BaseGameComponent,
}
</script>
