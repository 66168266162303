<template>
  <div id="schulteGrid-p">
    <schulte-grid> </schulte-grid>
  </div>
</template>

<script>
import schulteGrid from '@/components/pc/SchulteGridComponent.vue'
import BaseSchulteGrid from '@/views/common/BaseSchulteGrid.vue'

export default {
  components: { schulteGrid },
  extends: BaseSchulteGrid,
}
</script>

<style lang="scss" scoped>
</style>
