<template>
  <div id="userDetails">
    <!-- 数据操作成功提示信息 -->
    <v-snackbar v-model="snackbar" :timeout="2000" top shaped>
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-form ref="form" v-model="valid" class="mt-10">
      <v-card>
        <v-card-title>
          <span class="headline">用户中心</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.nickname"
                  label="昵称"
                  :rules="[rules.nickname]"
                ></v-text-field>
              </v-col>
              <v-col offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.real_name"
                  label="姓名"
                ></v-text-field>
              </v-col>
              <v-col offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.email"
                  label="邮箱"
                  :rules="[rules.email]"
                ></v-text-field>
              </v-col>
              <v-col offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.mobile"
                  label="联系电话"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.account_state"
                  label="账号状态"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col offset-md="1" sm="4">
                <v-text-field
                  v-model="editedItem.validate_date"
                  label="有效期限"
                  disabled
                ></v-text-field>
              </v-col>
              <!-- <v-col offset-md="1" sm="10">
                <v-text-field
                  v-model="editedItem.desription"
                  label="备注"
                ></v-text-field>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" @click="cancel" large> 取消 </v-btn> -->
          <v-btn
            color="blue darken-1"
            @click="editUser"
            :disabled="!valid"
            large
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { updateUserInfo } from '@/api/user-api/userApi'
// import { formatDateTime } from '@/utils'
import { checkRealName, checkMail, checkNickName } from './userUtils.ts'

export default {
  mounted() {
    this.getUserInfoFromStore()
  },
  watch: {
    pending: {
      handler() {
        if (this.pending) {
          this.pending = false
          this.searchTimer = setTimeout(() => {
            this.getUserInfoFromStore()
          }, 500)
        }
      },
    },
  },
  data() {
    return {
      // 刷新页面时，会暂时拿不到用户信息，pending500ms后刷新
      pending: false,
      // 验证表单
      valid: false,
      // 提示框
      snackbar: false,
      snackBarMessage: '',
      // 修改数据
      editedItem: {
        username: '',
        real_name: '',
        real_name_pinyin: '',
        openid: '',
        mobile: '',
        is_active: '',
        id_card: '',
        email: '',
        description: '',
        date_joined: '',
        account_state: '',
      },
      // 校验规则
      rules: {
        nickname: (v) => {
          return checkNickName(v)
        },
        realname: (v) => {
          return checkRealName(v)
        },
        email: (v) => {
          return checkMail(v, false)
        },
      },
    }
  },
  methods: {
    // 初始化用户数据
    getUserInfoFromStore() {
      const userInfo = this.$store.getters.getUserInfo
      if (userInfo === null) {
        this.pending = true
        return
      }
      this.editedItem = { ...userInfo }
    },
    // 关闭弹窗
    cancel() {
      const userInfo = this.$store.getters.getUserInfo
      if (userInfo === null) {
        this.pending = true
        return
      }
      this.editedItem = { ...userInfo }
    },
    // 新增用户保存
    editUser() {
      updateUserInfo(this.editedItem.id, this.editedItem).then((res) => {
        this.$store.dispatch('setUser', res.data)
        this.snackBarMessage = '修改用户成功!'
        this.snackbar = true
        this.editedItem = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
