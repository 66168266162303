<template>
  <div id="optionsComponent-p">
    <h2 class="mt-3 grey--text">{{ testConfig.title }}</h2>
    <v-container>
      <v-row class="mt-2">
        <v-col md="6">
          <!-- 题目数量及定时器 -->
          <v-row v-show="isTestData">
            <v-col cols="6">
              <h3 class="ml-5 mt-2 grey--text">
                第 {{ curQNum + 1 }} 题，共 {{ qCount }} 题
              </h3>
            </v-col>
            <v-col offset="1" ms="4">
              <time-counter-component
                :counterStart="counterStart"
                :intervalMs="timerIntervalMs"
              >
              </time-counter-component>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <img v-bind:src="qImg" :style="imgStyle" />
          </v-row>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col offset-md="1" md="4.5">
          <v-container v-if="isTestData">
            <h4 class="mt-3 grey--text">请选择答案</h4>
            <v-radio-group row v-model="selectAnswer" style="height: 50px">
              <v-radio
                v-for="item in answerOptions"
                :key="item"
                :label="item"
                :value="item"
                style="
                  margin-right: 6%;
                  margin-left: 6%;
                  margin-bottom: 1%;
                  margin-top: 1%;
                "
              ></v-radio>
            </v-radio-group>
          </v-container>
          <v-btn
            class="mt-5 ml-5"
            width="80%"
            height="60px"
            color="primary"
            v-if="!disableNavBtn"
            @click="preQuestion()"
            :disabled="disablePreBtn()"
          >
            上一题
          </v-btn>
          <v-btn
            class="mt-5 ml-5"
            width="80%"
            height="60px"
            color="primary"
            v-if="!disableNavBtn"
            @click="nextQuestion()"
            :disabled="disableNextBtn()"
          >
            下一题
          </v-btn>
          <v-btn
            class="mt-5 ml-5"
            width="80%"
            height="60px"
            color="primary"
            v-if="!isTestStart"
            @click="startTest()"
          >
            开始测试
          </v-btn>
          <v-btn
            class="mt-5 ml-5"
            width="80%"
            height="60px"
            color="primary"
            v-if="isTestStart"
            @click="doneTest()"
          >
            提交答案
          </v-btn>
          <v-btn
            class="mt-5 ml-5"
            width="80%"
            height="60px"
            color="primary"
            v-if="checkErrors"
            @click="setAnswerVisible()"
          >
            <span v-if="!answerVisible">显示答案</span>
            <span v-if="answerVisible">隐藏答案</span>
          </v-btn>
          <v-textarea
          label="正确答案"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="correctAnswer"
            v-if="showAnswer"
            readonly
            class="mt-5 ml-5"
            style="
              margin-right: 15%;
              margin-left: 5%;
              font-size: 100%;
              margin-top: 20px;
            "
        ></v-textarea>
          <!-- <v-text-field
            label="正确答案"
            v-model="correctAnswer"
            v-if="showAnswer"
            readonly
            style="
              margin-right: 15%;
              margin-left: 5%;
              font-size: 100%;
              margin-top: 20px;
            "
          ></v-text-field> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BaseComponent from '@/components/BaseComponent'
import { answerOptions } from '@/utils'
import timeCounterComponent from '@/components/common/timeCounterComponent'

export default {
  components: { timeCounterComponent },

  mounted() {
    this.setImageHeight(document.body.clientHeight)
  },
  watch: {
    getWindowHeight: {
      handler() {
        this.setImageHeight(this.getWindowHeight)
      },
      deep: true,
    },
  },
  methods: {
    // 根据答案显示选项
    getAnswerOptions() {
      const count = this.questions[this.curQNum].sub_count
      const answer = this.questions[this.curQNum].q_answer
      this.answerOptions = answerOptions(count, answer)
      this.selectAnswer = this.questions[this.curQNum].q_result
    },
    // 设置图片高度
    setImageHeight(value) {
      // 72px footer+margin, 64 px header bar, 36px title
      const winHeight = ((value - 72 - 64 - 36) * 3) / 4
      this.imgStyle.height = winHeight + 'px'
    },
  },
  computed: {
    // 正确答案
    correctAnswer() {
      return (
        this.questions[this.curQNum].q_answer +
        '.  ' +
        this.questions[this.curQNum].remark
      )
    },
  },
  extends: BaseComponent,
}
</script>

<style lang="scss" scoped>
.btn {
  margin-top: 15px;
  font-size: 20px;
  width: 150px;
  height: 60px;
  color: #415ae9;
}
</style>
