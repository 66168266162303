<template>
  <div id="user">
    <h1 class="mt-3 subtitle-1 grey--text">用户信息</h1>
    <!-- 数据操作成功提示信息 -->
    <v-snackbar v-model="snackbar" :timeout="2000" top shaped>
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 修改用户drawer -->
    <v-navigation-drawer right v-model="showEditDrawer" absolute width="450px">
      <p class="mt-3 ml-5 subtitle-1 grey--text">编辑用户信息</p>
      <v-divider></v-divider>
      <v-row class="mt-5 ml-8 mr-15">
        <v-col cols="12" md-8>
          <v-text-field
            label="用户ID"
            v-model="editedItem.username"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-text-field
            label="昵称"
            v-model="editedItem.nickname"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-text-field
            label="姓名"
            v-model="editedItem.real_name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-text-field
            label="电话"
            v-model="editedItem.mobile"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-text-field label="邮箱" v-model="editedItem.email"></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-select
            v-model="userState"
            :items="accountStates"
            label="账号状态"
          ></v-select>
        </v-col>
        <v-col cols="12" md-8>
          <v-text-field
            label="有效期"
            v-model="editedItem.validate_date"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md-8>
          <v-date-picker
            v-model="pickerDate"
            v-if="showDatePicker"
          ></v-date-picker>
        </v-col>

        <v-col cols="12" md-8>
          <v-text-field
            label="备注"
            v-model="editedItem.description"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-btn color="blue darken-1" text @click="cancelEdit"> 取消 </v-btn>
      <v-btn color="blue darken-1" text @click="saveEdit"> 保存 </v-btn>
      <v-btn color="red darken-1" text @click="resetPassword"> 重置密码 </v-btn>
    </v-navigation-drawer>
    <!-- 用户数据Data Table -->
    <v-data-table
      :headers="headers"
      :items="records"
      :options.sync="options"
      :server-items-length="totalRecords"
      :loading="loading"
      class="elevation-1 mt-8"
    >
      <!-- Data Table 上部信息，包括新增按钮，查询框等 -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>用户信息</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- 新增用户弹窗 -->
          <v-dialog id="addNewUser" v-model="addDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                添加用户
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">新增用户</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="editedItem.nickname"
                        label="昵称"
                        :rules="[rules.nickname]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="editedItem.real_name"
                        label="姓名"
                        :rules="[rules.realname]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="editedItem.email"
                        label="邮箱"
                        :rules="[rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="editedItem.mobile"
                        label="联系电话"
                        :rules="[rules.mobile]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="editedItem.desription"
                        label="备注"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel"> 取消 </v-btn>
                <v-btn color="blue darken-1" text @click="addUser">
                  保存
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 删除用户提示框 -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">确认删除当前用户?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">取消</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >确认</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- 重置密码确认 -->
          <v-dialog v-model="dialogResetPwd" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >确认为当前用户重置密码?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cancel">取消</v-btn>
                <v-btn color="blue darken-1" text @click="resetPasswordConfirm"
                  >确认</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon
          small
          @click="pressDelete(item)"
          :disabled="item.is_superuser === true"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  getUserList,
  addUserInfo,
  deleteUser,
  updateUserInfo,
  resetUserPassword,
} from '@/api/user-api/userApi'
import {
  checkRealName,
  checkMail,
  checkPhone,
  checkNickName,
} from './userUtils.ts'
import { formatDateTime, getSpecificDate } from '@/utils'
import { USER_ACCOUNT_STATES, USER_ACCOUNT_NOTMAL } from '@/const'

export default {
  data() {
    return {
      // 有效期选择框是否显示
      showDatePicker: false,
      // 日期选择器显示的日期
      pickerDate: '',
      // 账号状态
      userState: '',
      // 账号状态选项
      accountStates: USER_ACCOUNT_STATES,
      // 编辑用户信息drawer控制项
      showEditDrawer: false,
      // 全局查询延迟timer
      searchTimer: null,
      // 全局查询参数
      search: '',
      // snackbar控制项
      snackbar: false,
      // snackbar参数
      snackBarMessage: '',
      // 新增用户对话框控制项
      addDialog: false,
      // 删除用户确认对话框控制项
      dialogDelete: false,
      // 重置用户密码确认对话框控制项
      dialogResetPwd: false,
      // 以下为datatable参数
      totalRecords: 0,
      records: [],
      loading: true,
      options: {},
      // 表头
      headers: [
        {
          text: '用户ID',
          align: 'start',
          value: 'username',
        },
        { text: '昵称', value: 'nickname' },
        { text: '姓名', value: 'real_name' },
        { text: '邮箱', value: 'email' },
        { text: '联系电话', value: 'mobile' },
        { text: '账号状态', value: 'account_state' },
        { text: '有效期', value: 'validate_date' },
        { text: '创建时间', value: 'date_joined' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      // 用于记录删除对象
      opItem: null,
      // 新增用户model
      editedItem: {
        id: '',
        username: '',
        nickname: '',
        real_name: '',
        real_name_pinyin: '',
        openid: '',
        mobile: '',
        is_active: '',
        id_card: '',
        email: '',
        validate_date: '',
        description: '',
      },
      rules: {
        nickname: (v) => {
          return checkNickName(v)
        },
        realname: (v) => {
          return checkRealName(v)
        },
        email: (v) => {
          return checkMail(v, false)
        },
        mobile: (v) => {
          return checkPhone(v)
        },
      },
    }
  },
  watch: {
    // 监控data table查询的信息
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    // 监控全局搜索框
    search: {
      handler() {
        this.searchUser()
      },
      deep: true,
    },
    // 账号状态变化
    userState: {
      handler() {
        if (this.userState === USER_ACCOUNT_NOTMAL) {
          // const today = new Date()
          this.pickerDate = getSpecificDate(365)
          this.editedItem.validate_date = this.pickerDate
          this.showDatePicker = true
        } else {
          this.showDatePicker = false
          this.editedItem.validate_date = '2099-12-31'
        }
        this.editedItem.account_state = this.userState
      }
    },
    // 选择日期
    pickerDate: {
      handler() {
        this.editedItem.validate_date = this.pickerDate
      }
    }

  },
  methods: {
    // 清空编辑用户信息
    initEditUserInfo() {
      this.editedItem = {
        id: '',
        username: '',
        nickname: '',
        real_name: '',
        real_name_pinyin: '',
        openid: '',
        mobile: '',
        is_active: '',
        id_card: '',
        email: '',
        validate_date: '',
        description: '',
      }
    },
    // 根据全局搜索条件进行延迟查询
    searchUser() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      if (this.search) {
        this.searchTimer = setTimeout(() => {
          this.getDataFromApi()
        }, 500)
      } else {
        this.getDataFromApi()
      }
    },
    // 从后端获取数据并更新
    getDataFromApi() {
      this.searchTimer = null
      this.loading = true
      const parms = {
        page: 1,
        results: 10,
        search_global__icontains: this.search,
      }
      if (this.options.sortBy.length > 0) {
        // 仅支持一个字段的排序
        parms.sortField = this.options.sortBy[0]
        parms.sortOrder =
          this.options.sortDesc[0] === false ? 'ascend' : 'descend'
      } else {
        parms.sortField = null
        parms.sortOrder = null
      }

      getUserList(parms).then((data) => {
        this.records = []
        for (const idx in data.data) {
          data.data[idx].date_joined = formatDateTime(
            data.data[idx].date_joined
          )
          // const userInfo = {
          //   ...data.data[idx],
          //   datejoined: dataJoined,
          // }
          this.records.push(data.data[idx])
        }
        this.totalRecords = data.info.total
        this.loading = false
      })
    },
    // 关闭弹窗
    cancel() {
      this.addDialog = false
      this.dialogDelete = false
      this.dialogResetPwd = false
    },
    // 新增用户保存
    addUser() {
      addUserInfo(this.editedItem).then((res) => {
        this.snackBarMessage = '添加用户成功!'
        this.snackbar = true
        this.initEditUserInfo()
        this.getDataFromApi()
      })

      this.addDialog = false
    },
    // 处理编辑事件
    editItem(item) {
      this.editedItem = { ...item }
      this.userState = this.editedItem.account_state
      this.showEditDrawer = true
    },
    // 处理删除事件
    pressDelete(item) {
      this.opItem = item
      this.dialogDelete = true
    },
    // 确认删除用户
    deleteItemConfirm() {
      deleteUser(this.opItem.id).then(() => {
        this.snackBarMessage = '删除用户' + this.opItem.username + '成功!'
        this.opItem = null
        this.snackbar = true
        this.getDataFromApi()
      })
      this.dialogDelete = false
    },
    // 取消编辑
    cancelEdit() {
      this.showEditDrawer = false
    },
    // 保存修改信息
    saveEdit() {
      updateUserInfo(this.editedItem.id, this.editedItem).then((res) => {
        this.snackBarMessage = '修改用户信息成功!'
        this.snackbar = true
        this.initEditUserInfo()
        this.getDataFromApi()
        this.showEditDrawer = false
      })
    },
    // 重置密码
    resetPassword() {
      this.dialogResetPwd = true
    },
    // 确认重置密码
    resetPasswordConfirm() {
      const data = {
        username: this.editedItem.username,
      }
      resetUserPassword(data).then(() => {
        this.snackBarMessage = '重置密码成功!'
        this.snackbar = true
      })
      this.dialogResetPwd = false
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
