<template>
  <div class="mt-5" id="forget-pwd-p">
    <v-row>
      <v-col
      cols="0"
        offset-md="1"
        xs="12"
        md="5"
        style="min-height: 1px"
        v-if="isMobileLargeScreen()"
      >
        <v-card class="img-class">
          <img :src="registerImage" height="100%" width="100%" />
        </v-card>
      </v-col>
      <v-col cols="12" offset-md="1" md="4">
        <v-form ref="form" v-model="valid" class="mt-10 pa-10">
          <h1 class="text-center">忘记密码</h1>
            <v-text-field
              class="mt-10"
              v-model="userInfo.mobile"
              label="请输入注册手机号"
              :rules="[rules.mobile]"
            >
            </v-text-field>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="identifyCode"
                  label="验证码"
                  autocomplete="off"
                  clearable
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <div @click="refreshCode">
                  <s-identify :identifyCode="randomCode"></s-identify>
                </div>
              </v-col>
            </v-row>
            <v-text-field
              v-model="userInfo.rand_code"
              label="手机验证码"
              autocomplete="off"
              :rules="[rules.randCode]"
              clearable
            >
              <template v-slot:append-outer>
                <v-btn
                  depressed
                  class="primary"
                  :disabled="!enableSendSms()"
                  @click="getMobileCode()"
                  >{{ smsContent }}</v-btn
                >
              </template>
            </v-text-field>
            <v-text-field
              v-model="userInfo.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.password]"
              :type="showPassword ? 'text' : 'password'"
              label="请输入密码"
              hint="请输入8位密码"
              counter
              @click:append="showPassword = !showPassword"
            ></v-text-field>
            <v-text-field
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.samePwd]"
              :type="showPassword ? 'text' : 'password'"
              label="再次输入密码"
              counter
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-form>

          <v-btn
            block
            class="mt-8"
            color="primary"
            x-large
            v-on:click.prevent="resetPassword()"
            :disabled="!valid"
          >
            重置密码
          </v-btn>
          <v-btn
            block
            class="mt-8"
            color="primary"
            x-large
            v-on:click.prevent="toLogin()"
          >
            返回登录
          </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BaseForgetPwd from '@/views/common/BaseForgetPwd.vue'
import SIdentify from '@/components/common/identifyCode.vue'

export default {
  components: { SIdentify },
  mounted() {
    this.refreshCode()
  },
  extends: BaseForgetPwd,
}
</script>

<style lang="scss" scoped>
.remember-user {
  font-size: 90%;
  margin-left: 5px;
}
.forget-pwd {
  display: block;
  font-size: 90%;
  color: blue;
  text-align: right;
  text-decoration: underline;
}
.img-class {
  overflow: hidden;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
